<template>
  <div class="BOX">
    <div class="wh">&nbsp;</div>
    <div class="one-box3">
      <div class="one-box2-box" @click="blck">
        <img src="../assets/img/back.png" alt="" class="pic-back" />
      </div>
      <div class="one-box2-box2">{{ language.a }}</div>
      <div>&nbsp;</div>
    </div>
    <div class="wh">&nbsp;</div>

    <div class="one-box" v-for="(item, index) in items" :key="index">
      <div class="wh2">&nbsp;</div>
      <div class="one-box1">
        <div class="one-box2">
          &nbsp;&nbsp;&nbsp;&nbsp;{{ language.b }}&nbsp;
        </div>
        <div class="box-2-2">{{ item.ctime }}</div>
      </div>

      <div class="one-box1">
        <div class="one-box2">
          &nbsp;&nbsp;&nbsp;&nbsp;{{ language.c }}&nbsp;
        </div>
        <div class="box-2-2">
          {{ item.amount }}
        </div>
      </div>

      <div class="one-box1">
        <div class="one-box2">
          &nbsp;&nbsp;&nbsp;&nbsp;{{ language.d }}&nbsp;
        </div>
        <div class="box-2-2">{{ handing }}%</div>
      </div>

      <div class="one-box1">
        <div class="one-box2">
          &nbsp;&nbsp;&nbsp;&nbsp;{{ language.e }}&nbsp;
        </div>
        <div class="box-2-2">
          {{ item.actually }}
        </div>
      </div>

      <div class="one-box1">
        <div class="one-box2">
          &nbsp;&nbsp;&nbsp;&nbsp;{{ language.f }}&nbsp;
        </div>
        <div class="box-2-2">
          {{ item.name }}
        </div>
      </div>

      <div class="one-box1">
        <div class="one-box2">
          &nbsp;&nbsp;&nbsp;&nbsp;{{ language.g }}&nbsp;
        </div>
        <div class="box-2-2">
          {{ zhuanhuan(item.bankcode) }}
        </div>
      </div>

      <div class="one-box1">
        <div class="one-box2">
          &nbsp;&nbsp;&nbsp;&nbsp;{{ language.h }}&nbsp;
        </div>
        <div class="box-2-2">
          {{ item.type == "银行转账" ? language.j : "" }}
        </div>
      </div>

      <div class="one-box1">
        <div class="one-box2">
          &nbsp;&nbsp;&nbsp;&nbsp;{{ language.i }}&nbsp;
        </div>
        <div
          class="box-2-2"
          v-if="item.status == '审核中'"
          style="color: orange"
        >
          {{ language.k }}
        </div>
        <div class="box-2-2" v-if="item.status == '成功'" style="color: green">
          {{ language.l }}
        </div>
        <div class="box-2-2" v-if="item.status == '失败'" style="color: red">
          {{ language.m }}
        </div>
      </div>

      <div class="one-box1" v-if="item.status == '失败'">
        <div class="one-box2" style="color: green">
          &nbsp;&nbsp;&nbsp;&nbsp;{{ language.n }}&nbsp;
        </div>
        <div class="box-2-2" style="color: green">
          {{ item.info }}
        </div>
      </div>
      <!-- 附言代码 -->
      <div class="one-box1">
        <div class="one-box2">&nbsp;&nbsp;&nbsp;&nbsp;{{language.o}}&nbsp;</div>
        <div class="box-2-2">
          {{ item.info }}
        </div>
      </div>
      <div class="wh2">&nbsp;</div>

      <!-- <div class="one-box1"><div class="one-box2">&nbsp;&nbsp;&nbsp;&nbsp;777</div><div class="box-2-2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;777</div></div>
        <div class="one-box1"><div class="one-box2">&nbsp;&nbsp;&nbsp;&nbsp;888</div><div class="box-2-2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;审核中</div></div> -->
    </div>
    <Menu></Menu>
  </div>
</template>

<script>
import axios from "axios";
import Menu from "../components/Menu.vue";
export default {
  components: {
    Menu,
  },
  computed: {},
  methods: {
    // zhuanhuan(jc){
    //   if (jc.length == 10) {
    //     let star = jc.substring(0, 3);
    //     let middleStars = "****";
    //     let end = jc.substring(7, 11);
    //      return star + middleStars + end
    //   }
    //   if (jc.length == 11) {
    //     let star = jc.substring(0, 3);
    //     let middleStars = "*****";
    //     let end = jc.substring(8, 12);
    //      return star + middleStars + end
    //   }
    //   if (jc.length == 12) {
    //     let star = jc.substring(0, 3);
    //     let middleStars = "*****";
    //     let end = jc.substring(9, 13);
    //     return star + middleStars + end
    //   }
    // },
    zhuanhuan(jc) {
      if (jc.length == 10) {
        let star = "**";
        let middleStars = "****";
        let end = jc.substring(6, 11);
        return star + middleStars + end;
      }
      if (jc.length == 11) {
        let star = "***";
        let middleStars = "****";
        let end = jc.substring(7, 12);
        return star + middleStars + end;
      }
      if (jc.length == 12) {
        let star = "****";
        let middleStars = "****";
        let end = jc.substring(8, 13);
        return star + middleStars + end;
      }
    },

    Records() {
      let token = sessionStorage.getItem("token");
      //// //console.log(token);
      axios
        .get(`/siteinfo/selectwithdraw.do`)
        .then((res) => {
          if (res.data.code == 200) {
            ////console.log(res);
            this.handing = res.data.data;
            // //console.log(res.data.data)
          }
        })
        .catch();

      axios
        .get(`/withdraw/quierymy.do?uid=${+token}`, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
          },
        })
        .then((res) => {
          //console.log(res);
          if (res.data.code == 200) {
            this.items = res.data.data.reverse();
            // // //console.log(this.WithdrawalName)
            // let token2 = sessionStorage.getItem("token")
            // // //console.log(this.WithdrawalName)
            this.items.forEach((elm) => {
              if (elm.status == "0") {
                elm.status = "审核中";
              } else if (elm.status == "1") {
                elm.status = "成功";
              } else if (elm.status == "2") {
                elm.status = "失败";
              }
            });
            //          this.items.forEach((jc)=>{
            //           //// //console.log("666666666666666666");
            //            //// //console.log(jc.bankcode.length);
            //           if (jc.bankcode.length == 10) {
            //       let star = jc.bankcode.substring(0, 3);
            //       let middleStars = "****";
            //       let end = jc.bankcode.substring(7, 11);
            //       jc.bankcode = star + middleStars + end;
            //     }
            //     if (jc.bankcode.length == 11) {
            //       let star = jc.bankcode.substring(0, 3);
            //       let middleStars = "*****";
            //       let end = jc.bankcode.substring(8, 12);
            //      jc.bankcode = star + middleStars + end;
            //        //// //console.log(jc.bankcode.length);
            //     }
            //     if (jc.bankcode.length == 12) {
            //       let star = jc.bankcode.substring(0, 3);
            //       let middleStars = "*****";
            //       let end = jc.bankcode.substring(9, 13);
            //       jc.bankcode = star + middleStars + end;
            //     }

            // //// //console.log(this.jc.bankcode);
            //          });
          }
        })
        .catch();
    },
    blck() {
      this.$router.push("/my");
    },
  },
  mounted() {
    if (localStorage.getItem("language") == "chinese") {
      this.language = this.languageChinese;
    }
    if (localStorage.getItem("language") == "english") {
      this.language = this.languageEnglish;
    }
    if (localStorage.getItem("language") == "JT") {
      this.language = this.languageJT;
    }
    if (localStorage.getItem("language") == "HY") {
      this.language = this.languageHY;
    }
    if (localStorage.getItem("language") == "TY") {
      this.language = this.languageTY;
    }
    if (localStorage.getItem("language") == "YDY") {
      this.language = this.languageYDY;
    }
    if (localStorage.getItem("language") == "RY") {
      this.language = this.languageRY;
    }
    this.Records();
    this.zhuanhuan();
  },
  data() {
    return {
      WithdrawalName: "",
      zh: "",
      items: [],
      handing: 0,
      // 翻译数组
      language: {
        a: "提现记录",
        b: "时间:",
        c: "金额:",
        d: "手续费:",
        e: "实际到账:",
        f: "姓名:",
        g: "银行卡号:",
        h: "类型:",
        i: "状态:",
        j: "银行转账",
        k: "审核中",
        l: "成功",
        m: "失败",
        n: "原因:",
        o: "附言：",
        
      },
      languageChinese: {
        a: "提现记录",
        b: "时间:",
        c: "金额:",
        d: "手续费:",
        e: "实际到账:",
        f: "姓名:",
        g: "银行卡号:",
        h: "类型:",
        i: "状态:",
        j: "银行转账",
        k: "审核中",
        l: "成功",
        m: "失败",
        n: "原因:",
        o: "附言：",
      },
      languageEnglish: {
        a: "Withdrawal Record",
        b: "Time:",
        c: "Amount:",
        d: "Handling Fee:",
        e: "Actual Amount Received:",
        f: "Name:",
        g: "Bank Card Number:",
        h: "Type:",
        i: "Status:",
        j: "AI system",
        k: "Pending Review",
        l: "Successful",
        m: "Failed",
        n: "Reason:",
        o: "Remarks：",
        
      },
      //繁体中文数组
      languageJT: {
        a: "提現記錄",
        b: "時間:",
        c: "金額:",
        d: "手續費:",
        e: "實際到賬:",
        f: "姓名:",
        g: "銀行卡號:",
        h: "類型:",
        i: "狀態:",
        j: "AI system",
        k: "審核中",
        l: "成功",
        m: "失敗",
        n: "原因:",
        o: "附言：",
        
      },
      //泰语数组
      languageTY: {
        a: "บันทึกการถอนเงิน",
        b: "เวลา：",
        c: "วงเงิน：",
        d: "ค่าธรรมเนียม：",
        e: "วงเงินทีเข้า：",
        f: "ชื่อ：",
        g: "หมายเลขบัญชี：",
        h: "ประเภท：",
        i: "สถานะ：",
        j: "AI system",
        k: "กำลังตรวจสอบ",
        l: "สำเร็จ",
        m: "ล้มเหลว",
        n: "เหตุผล:",
        o: "ระบุ：",
        
      },

      //韩语数组
      languageHY: {
        a: "출금기록",
        b: "시간:",
        c: "금액:",
        d: "수수료:",
        e: "실제입금:",
        f: "이름:",
        g: "은행카드번호:",
        h: "유형:",
        i: "상태:",
        j: "AI system",
        k: "심사중",
        l: "성공",
        m: "실패",
        n: "이유:",
        o: "부속말：",
       
      },

      //日语数组
      languageRY: {
        a: "現金引き出し記録",
        b: "時間:",
        c: "金額:",
        d: "手数料:",
        e: "実際の入金額:",
        f: "氏名:",
        g: "銀行カード番号:",
        h: "種類:",
        i: "状態:",
        j: "AI system",
        k: "審査中",
        l: "成功",
        m: "失敗",
        n: "理由:",
        o: "添え書き：",
        
      },
      languageYDY: {
        a: "कैश आउट रिकॉर्ड",
        b: "समय:",
        c: "राशि:",
        d: "हैंडलिंग शुल्क:",
        e: "वास्तविक पहुंच:",
        f: "नाम:",
        g: "बैंक कार्ड नंबर:",
        h: "प्रकार:",
        i: "स्थिति:",
        j: "AI system",
        k: "रिভিजन में",
        l: "सफल",
        m: "असफल",
        n: "कारण",
        o: "नोट：",
        
      },
    };
  },
};
</script>

<style scoped>
.BOX {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 60px;
  height: auto;
  background-color: rgb(255, 255, 255);
}
.one-box {
  border: 1px solid rgb(187, 180, 180);
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #eaeaea;
  border-left: none;
  border-right: none;
}
.one-box1 {
  padding: 3px;
  display: flex;
  width: 100%;

  color: rgb(144, 148, 148);
  font-size: 15px;
   height: 30px;
  line-height: 30px;
}
.one-box2 {
  display: flex;
  font-size: 14px;
}
.one-box3 {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.one-box2-box {
  display: flex;
  justify-content: center;
}

.one-box2-box img {
  cursor: pointer;
}

.one-box2-box2 {
  width: 50%;
  display: flex;
  justify-content: center;
}
.pic-back {
  width: 10px;
  height: auto;
}
.box-2-2 {
  font-size: 14px;
   height: 30px;
  line-height: 30px;
  /* border: 1px solid red; */
  /* padding: 3px; */
}
.wh {
  width: 100%;
  height: 10px;
}
.wh2 {
  width: 100%;
  height: 13px;
}
</style>